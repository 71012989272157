<template>
  <div class="container">
    <h1>日上券 - 前台购券</h1>
    <div class="filter-box">
      <div class="left-box">
        <div class="item">
          <el-input v-model="params.KeyWords" size="small" placeholder="关键字" clearable />
        </div>
        <div class="item">
          <el-button type="primary" size="small" icon="el-icon-search" @click="handleFilter">搜索</el-button>
          <el-button type="default" size="small" icon="el-icon-close" @click="handleCleanFilter">清除筛选</el-button>
        </div>
      </div>
      <div class="right-box">
        <el-button type="primary" size="small" icon="el-icon-download" @click="handleExport">导出 Excel</el-button>
      </div>
    </div>
    <div class="table-box">
      <el-table :data="items" border style="width: 100%">
        <el-table-column prop="orderNo" label="订单号" min-width="150" />
        <el-table-column prop="title" label="商品名称" min-width="300" />
        <el-table-column prop="linker" label="联系人" min-width="150" />
        <el-table-column prop="linkerMobile" label="联系电话" min-width="150" />
        <el-table-column prop="voucherCode" label="券码" min-width="150" />
        <el-table-column prop="startValidTime" label="有效期开始时间" min-width="140" />
        <el-table-column prop="endValidTime" label="有效期结束时间" min-width="140" />
        <el-table-column prop="status" label="状态" min-width="100">
          <template #default="scope">
            {{ statusFormat(scope.row.status) }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin:20px auto; text-align:center">
      <el-pagination :page-size="params.Take" :total="totalCount" background layout="total, prev, pager, next"
        @current-change="handlePageChange" />
    </div>
  </div>
</template>

<script>
  import {
    getOrderList,
  } from "@/api/lounge"
  import {
    formatJsonDate
  } from "../../utils/dateFormat"
  import {
    createFile,
    download
  } from '../../utils/excel'

  export default {
    data() {
      return {
        loading: false,
        params: {
          Skip: 0,
          Take: 20,
          KeyWords: null
        },
        items: [],
        totalCount: 0,
        dialogVisible: false,
        refreshItems: [],
        refreshIndex: 0,
        refreshPercent: 0,
        progressText: ''
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        getOrderList(this.params.KeyWords, this.params.Skip, this.params.Take)
          .then(res => {
            if (res.success) {
              this.items = res.items
              this.totalCount = res.totalCount
            }
          })
      },
      dateFormat(time) {
        return formatJsonDate(time)
      },
      sourceFormat(source) {
        switch (source) {
          case 1:
            return '微信支付'
          case 2:
            return '余额支付'
          default:
            return source
        }
      },
      statusFormat(status) {
        switch (status) {

          case 1:
            return '未使用'
          case 2:
            return '已使用'
          case 3:
            return '已失效'
          case 4:
            return '已退款'
          default:
            return status
        }
      },
      handleFilter() {
        this.params.Skip = 0
        this.init()
      },
      handleCleanFilter() {
        this.params.Skip = 0
        this.params.KeyWords = null
        this.init()
      },
      handleExport() {
        getOrderList(this.params.KeyWords, 0, 0)
          .then(res => {
            if (res.success) {
              let exportData = res.items.map(d => {
                return {
                  '订单号': d.orderNo,
                  '商品名称': d.title,
                  '券码': d.voucherCode,
                  '有效期开始时间': d.startValidTime,
                  '有效期结束时间': d.endValidTime,
                  '状态': this.statusFormat(d.status)
                }
              })

              let columnWidths = [{
                wpx: 150
              }, {
                wpx: 300
              }, {
                wpx: 150
              }, {
                wpx: 140
              }, {
                wpx: 140
              }, {
                wpx: 100
              }]

              var file = createFile(exportData, columnWidths)
              download(file, '贵宾室订单', document)
            }
          })
      },
      handlePageChange(page) {
        this.params.Skip = (page - 1) * this.params.Take
        this.init()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .filter-box {
      &:after {
        content: "";
        display: table;
        clear: both;
      }

      .left-box {
        float: left;

        .item {
          float: left;
          margin-right: 10px;
        }
      }

      .right-box {
        float: right;
      }
    }

    .table-box {
      margin-top: 20px;
    }
  }
</style>
