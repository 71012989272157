import request from '@/utils/request'

export function getOrderList(keyword, skip, take) {
  return request({
    url: '/Lounge/GetOrderList',
    method: 'post',
    params: {
      keyword,
      skip,
      take
    }
  })
}
